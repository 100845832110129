import { inject, Injectable } from '@angular/core';
import { ApiHomePage, ApiHomeRow, ApiTalent, ApiTopic, PagedArray, PagesApi, TopicApi } from '@tytapp/api';
import { ClientPersistenceService } from '@tytapp/app/client-persistence.service';
import { ApiStateTransfer } from '@tytapp/app/middleware/api-state-transfer';
import { Cache, LoggerService } from '@tytapp/common';
import { isClientSide, isOnline } from '@tytapp/environment-utils';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class TopicsService {
    private topicApi = inject(TopicApi);
    private pagesApi = inject(PagesApi);
    private persistence = inject(ClientPersistenceService);
    private topics: ApiTopic[];
    private topicsListCache: Cache<ApiTopic[]> = Cache.shared<ApiTopic[]>('topics-list', 1000 * 60 * 15, 1);
    private followedTopicsCache: Cache<ApiHomePage> = new Cache<ApiHomePage>('followed-topics', 1000 * 60 * 5, 1);
    private apiStateTransfer = inject(ApiStateTransfer);

    /**
     * @deprecated Includes hosts & politicians which are deprecated. Use Talent instead.
     * @returns
     */
    async allWithLegacyTopics() {
        this.topics ??= await this.persistence.fetchWhenOnline(
            'topics',
            () => this.topicsListCache.fetch('default', () => this.allUncached())
        );
        this.apiStateTransfer.prepopulate(`/content/topics`, {}, this.topics);
        return this.topics;
    }

    async all() {
        return (await this.allWithLegacyTopics()).filter(x => x.topic_type === 'topic');
    }

    async followed() {
        let homePage = await this.followedTopicsCache.fetch('followedTopics', () => firstValueFrom(this.pagesApi.getFollowedTopics()), {
            missStrategy: 'fulfill'
        });

        return homePage.rows;
    }

    private async allUncached() {
        return await this.topicApi.all().toPromise();
    }
}